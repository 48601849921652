import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { User } from '@entities/users/user.entity';
import { UserRole } from '@entities/users/user-role.enum';
import { Md5 } from 'ts-md5';
import { AuthenticatedUser } from '@entities/authentication/authenticated-user.entity';

// import { Md5 } from 'ts-md5';

@Component({
  selector: 'pd-gravatar',
  standalone: true,
  imports: [CommonModule],
  template: `<div [ngClass]="classes">
    <!--    <img-->
    <!--      *ngIf="!useInitials"-->
    <!--      alt="User Profile"-->
    <!--      class="h-full w-full rounded-full"-->
    <!--      [src]="emailUrl"-->
    <!--      (error)="onImageError()" />-->
    <div
      *ngIf="useInitials"
      [style.background-color]="backgroundColor ? backgroundColor + ' !important' : null"
      [ngClass]="backgroundColor ? 'text-white' : 'text-gray-700'"
      class="align-center flex h-full w-full flex-col justify-center rounded-full bg-gray-200 text-center dark:bg-gray-100">
      <div
        class="font-semibold"
        [ngStyle]="{ fontSize: $any(size) * 2 + 'px', height: '100%', lineHeight: '200%', textOverflow: 'clip' }">
        {{ user?.initials }}
      </div>
    </div>
  </div>`,
})
export class GravatarComponent implements OnInit {
  @Input()
  public user: User | AuthenticatedUser;
  @Input()
  public size: '8' | '10' | '12' | '16' | '32' | '64' = '8';
  // Hack to get tailwind to always include these (mentioning them includes them): w-8 w-10 w-12 w-16 w-32 w-64
  // tailwind hates dynamic classes, it does not compile the CSS unless we mention full class names
  // it uses (due to treeshaking behaviour). Alternatively, we can use safelist in the tailwind config

  public classes: string[] = [];
  protected emailUrl: string;
  protected useInitials = true;
  protected backgroundColor: string = null;

  public ngOnInit(): void {
    this.classes = ['min-w-8', 'w-' + this.size, 'h-' + this.size];
    this.backgroundColor = this.getBackgroundColorByRole(this.user.roleId);
    if (this.user.email) {
      this.emailUrl = '//www.gravatar.com/avatar/' + Md5.hashStr(this.user.email.trim().toLowerCase()) + '?d=404';
    }
  }

  private getBackgroundColorByRole(role: UserRole): string {
    switch (role) {
      case UserRole.SuperAdmin:
        return '#3E1DA7';
      case UserRole.Admin:
        return '#3E1DA7';
      case UserRole.Worker:
        return '#7448FF';
      case UserRole.Manager:
        return '#442CA8';
      case UserRole.Approver:
        return '#A286FD';
      default:
        return null;
    }
  }
}
